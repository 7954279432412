import { createSelector } from '@reduxjs/toolkit';
import { round, withUnit, fraction, unum } from '@oliasoft-open-source/units';
import { initialWellDetails } from './well-details';
import { selectActiveCostItemQuantity } from '../cost-setup/selector';

const selectWellDetails = ({ entities }) => entities.wellDetails;

export const selectWellDetailsProjects = createSelector(
  [selectWellDetails],
  ({ projects, countries }) => {
    const projectsWithCountryName = projects?.map((project) => ({
      ...project,
      country: countries?.find((item) => project.country === item.code)?.name,
    }));
    return projectsWithCountryName;
  },
);

export const selectWellDetailsWell = createSelector(
  [selectWellDetails],
  (wellDetails) => wellDetails.well,
);

export const selectSectionTypes = createSelector(
  [selectWellDetails],
  (wellDetails) => wellDetails.sectionTypes,
);

export const selectWellDetailsSchematic = createSelector(
  [selectWellDetails],
  (wellDetails) => wellDetails.schematic,
);

export const selectIsInitialWellDetails = createSelector(
  [selectWellDetailsWell, selectWellDetailsSchematic],
  (well, schematic) => {
    return (
      well.wellConfiguration === initialWellDetails.wellConfiguration &&
      well.drillingUnit === initialWellDetails.drillingUnit &&
      !(
        well.airGap ||
        well.waterDepth ||
        well.wellheadDepth ||
        schematic.length
      )
    );
  },
);

export const selectWellLocation = createSelector(
  [selectWellDetailsWell],
  (wellDetails) => wellDetails.location,
);

export const selectProjectInfo = createSelector(
  [selectWellDetailsWell, selectWellDetailsProjects],
  (well, projects) => {
    const projectInfo = projects.find(
      (project) => project.datasetid === well.datasetId,
    );
    return projectInfo;
  },
);

export const selectIsGenerateFromSchematic = createSelector(
  [selectWellDetailsWell],
  (well) => {
    return well.isGenerateFromSchematic;
  },
);

const selectWellDetailsSections = createSelector(
  [selectWellDetails],
  (wellDetails) => wellDetails.sections,
);

const suggestedVolumeValue = (item) =>
  round(
    (Math.PI / 4) *
      (unum(fraction(item?.holeSize), 'm', 'in') ** 2 -
        (item?.section ? unum(fraction(item?.section), 'm', 'in') : 0) ** 2) *
      (item?.bottom - item?.toc),
    2,
  );
const suggestedVolumeValueForSectionHoleSize = (item, holeSize) =>
  round((Math.PI / 4) * unum(fraction(holeSize), 'm', 'in') ** 2 * item, 2);

export const selectSectionSuggestedValues = createSelector(
  [
    selectWellDetailsSchematic,
    selectWellDetailsSections,
    selectActiveCostItemQuantity,
    selectSectionTypes,
  ],
  (schematic, sections, quantity, sectionTypes) => {
    const optionList = schematic
      .map((schematicItem) => {
        const tubularType =
          sections.find(
            (section) => section.sectionId === schematicItem.sectionId,
          )?.name || '';
        const sectionType =
          sectionTypes.find(
            (section) => section.sectionTypeId === schematicItem.sectionTypeId,
          )?.name || '';
        return {
          label: `${
            schematicItem?.holeSize ? `${schematicItem?.holeSize}'' - ` : ''
          }${tubularType} ${sectionType ? `- ${sectionType}` : ''}`,
          value:
            quantity === 'length'
              ? schematicItem.sectiontd - schematicItem.top
              : suggestedVolumeValue(schematicItem),
          valueKey: schematicItem.schematicId,
        };
      })
      .filter((o) => o.value >= 0)
      .map((o) => ({
        ...o,
        value: withUnit(o.value, quantity === 'length' ? 'm' : 'm3'),
      }));
    return optionList;
  },
);

export const selectSectionSuggestedValuesForHoleSize = createSelector(
  [
    selectWellDetailsSchematic,
    selectWellDetailsSections,
    selectActiveCostItemQuantity,
    selectSectionTypes,
  ],
  (schematic, sections, quantity, sectionTypes) => {
    const optionList = schematic
      .map((schematicItem, i, schematic) => {
        const tubularType =
          sections.find(
            (section) => section.sectionId === schematicItem.sectionId,
          )?.name || '';
        const sectionType =
          sectionTypes.find(
            (section) => section.sectionTypeId === schematicItem.sectionTypeId,
          )?.name || '';
        const lastSectionTd = schematic[i - 1]?.sectiontd || 0;
        const sectionDifference = schematicItem.sectiontd - lastSectionTd;
        return {
          label: `${
            schematicItem?.holeSize ? `${schematicItem?.holeSize}'' - ` : ''
          }${tubularType} ${sectionType ? `- ${sectionType}` : ''}`,
          value: suggestedVolumeValueForSectionHoleSize(
            sectionDifference,
            schematicItem.holeSize,
          ),
          valueKey: schematicItem.schematicId,
        };
      })
      .filter((o) => o.value >= 0)
      .map((o) => ({
        ...o,
        value: withUnit(o.value, 'm3'),
      }));
    return optionList;
  },
);
